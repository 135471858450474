
const Contact = () => {

    return (
        <section className="contact">
            <h1>Contact</h1>
            <p>To get in touch, you can find me on linked in (bottom of the page) or email me directly at timwtucker@hotmail.com</p>
        </section>
    )
}

export default Contact;