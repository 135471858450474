import {Link} from 'react-router-dom';

const ProjectThumbnail = ({project}) => {

    const border = project.title === "OddJobs" ? false : true;

    return (
        
        <div className="project-thumbnail">
            <Link to={`/projects/${project.path}`}>
                <div>
                    <h3>{project.title}</h3>
                    <p>{project.subtitle}</p>
                </div>
                <div className="image-container">
                    <img style={border ? {border: 'thin solid black'}: {}}
                    src={project.thumbnail} alt={`Image of ${project.title} project`}/>
                </div>
            </Link>
        </div>
    )
}

export default ProjectThumbnail;