import About from "./About"
import Contact from "./Contact"
import ProjectPage from "./ProjectsPage"

const Home = () => {

    return (
        <>
            <ProjectPage/>
            <About/>
            <Contact/>
        </>
    )


}

export default Home