import { Link } from "react-router-dom";

const NavBar = () => {

    return (
        <div className="navbar">
             <Link to='/'>
                 <h1> Tim Tucker</h1>
            </Link>
            <div className="navbar-links" >
                <Link to='/projects'>Projects</Link>
                <Link to='/about'>About</Link>
                <Link to='/contact'>Contact</Link>
            </div>
        </div>
    );
}

export default NavBar;