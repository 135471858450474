import projectData from '../projectDescriptions.json';
import ProjectThumbnail from './ProjectThumbnail';


const ProjectPage = () => {

    console.log(projectData);
    return (
        <>
            <h1>Projects</h1>
            <section className="project-page">
                {projectData.map(project => <ProjectThumbnail project={project}/>)}
            </section>
        </>
    )
}

export default ProjectPage;