import projectData from '../projectDescriptions.json';
import {useParams} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import techStack from '../techStack.json';
import Tech from './Tech';

const Project = () => {

    const {project_path} = useParams();
    const navigate = useNavigate();

    let project = projectData.filter(project => project.path === project_path);
    project = project.length ? project[0] : null;

    if (!project)
        return <h1>404 - Project Not Found</h1>

    const border = project.title === "OddJobs" ? false : true;

    return (
        <>
            <div className="project-title">
                <div>
                    <button onClick={() => navigate(-1)}>⇦</button>
                </div>
                <h1>{project.title}</h1>
                <div></div> {/* This is important! It keeps the title centered */}
            </div>
            <section>
                <div className="project-description">
                    <div className="project-links">
                        {project.link && 
                        <a href={project.link}>Demo</a>}
                        {project.github &&
                        <a href={project.github}>Github</a>}
                    </div>
                    <p>{project.description}</p>
                </div>
                <div className="screen-shots">
                    {project.imgs.map(image => 
                        <div>
                            <p>{image.description}</p>
                            <img style={border ? {border: 'thin solid black'}: {}}
                                src={image.img} alt={image.description}></img>
                        </div>
                    )}
                </div>
            </section>
            <section>
                <h1 className="tech-stack-header">Tech Stack</h1>
                <div className="tech-items">
                    {project.techStack.map(tech => <Tech tech={techStack[tech]}/>)}
                </div>
            </section>
        </>
    )
}

export default Project;