import { Link } from "react-router-dom";

const Tech = ({tech}) => {

    return (
        <a href={tech.website} className="tech-item">
            <img src={tech.img} alt={`${tech.name} logo`}/>
            <h3>{tech.name}</h3>
        </a>
    )
}

export default Tech;