import './App.css';
import NavBar from './components/NavBar.jsx';
import {Routes, Route} from 'react-router-dom';
import ProjectThumbnail from './components/ProjectThumbnail';
import ProjectPage from './components/ProjectsPage';
import Project from './components/Project';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import Home from './components/Home';
import NotFound from './components/NotFound';

function App() {
  return (
    <div className="App">
      <NavBar/>
        <main>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/projects' element={<ProjectPage/>}/>
            <Route path='/projects/:project_path' element={<Project/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='*' element={<NotFound/>}/>
          </Routes>
        </main>
        <Footer/>
    </div>
  );
}

export default App;
