import {useState} from 'react';

const Footer = () => {

    const [githubHover, setGithubHover] = useState(false);
    const [linkedInHover, setLinkedInHover] = useState(false);

    return (
        <section className="footer">
            <div className="github">
                <a href="https://github.com/timwtuck">
                    <img src={githubHover ? '/images/github-pressed.png' : '/images/github.png'}
                        alt="Github Logo"
                        onMouseOver={() => setGithubHover(true)}
                        onMouseOut={() => setGithubHover(false)} />
                </a>
                <h3>Github</h3>
            </div>
            <div className="linkedin">
                <a  href="https://www.linkedin.com/in/timwtucker/">
                    <img src={linkedInHover ? '/images/linkedin-pressed.png' : '/images/linkedin.png'}
                        alt="Linked in Logo"
                        onMouseOver={() => setLinkedInHover(true)}
                        onMouseOut={() => setLinkedInHover(false)} />
                </a>
                <h3>Linked In</h3>
            </div>
        </section>
    )
}

export default Footer;