
const About = () =>{

    return (
        <>
        <h1>About</h1>
        <section className="about">
            <div>
                <img src="/images/tim.png"/>
                <p>Me doing Blue Steel</p>
            </div>
            <div>
                <p>I am an engineering graduate with some previous experience in software development. Since then, I have expanded my non-technical skillset, getting involved in the running of a small craft beer start-up and teaching abroad at a Chinese university, strengthening my communication and interpersonal skills, as well as gaining a real appreciation for the challenges of running a business.</p>
                <p>During lockdown I decided to code a trading bot for an electronic trading platform, rekindling my love for problem solving and the pure satisfaction in creating something. Having rediscovered this passion for programming, I enrolled on a reputable coding bootcamp to refresh myself with relevant skills in order to find a role with a breadth of challenges and opportunities to learn.</p>
            </div>
        </section>
        </>
    )
}

export default About;